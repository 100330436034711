import React from 'react';
import { Label } from 'reactstrap'
import { default as ReactSelect } from "react-select"
import DatePicker from "react-datepicker";
import keyBy from 'lodash/keyBy';
import swal from 'sweetalert';
import Header from '../../../Layout/Header';
import Sidebar from '../../../Layout/Sidebar';
import Footer from '../../../Layout/Footer';
import CollaboratorApi from '../../../ProjectCollaborator/CollaboratorApi';
import Apis from '../../../../../api';



function Safety() {
    const { teamList } = CollaboratorApi()
    const [dashboardData, setDashboardData] = React.useState([]);
    const [safetyData, setSafetyData] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false)
    const [teams, setTeams] = React.useState([])
    const [calendar, setCalendar] = React.useState([])
    const [workingDays, setWorkingDaya] = React.useState([])
    const [safetyDataCount, setSafetyCount] = React.useState({
        todayDays: 0,
        weekend: 0,
        workingDay: 0,
        incident: 0,
        no_incident: 0,
    });
    const [filters, setFilters] = React.useState({ team: '', date: new Date() })

    React.useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])
    React.useEffect(() => {
        Apis.getCalendar().then((res) => {
            var calendarData = res.data.data
            if (calendarData.projectSetting !== undefined) {
                setCalendar(calendarData.projectSetting.value.dates)
            }

        }).catch(error => {
            console.log(error.response.data)
        })
    }, [])

    React.useEffect(() => {
        var tempWorkingDay = []
        calendar && calendar.map((elm) => {
            var demo = {
                timeStamp: new Date(new Date(elm.date).setHours(0, 0, 0, 0)).getTime(),
                isWorkingDay: elm.isWorkingDay
            }
            tempWorkingDay.push(demo)

        })
        setWorkingDaya(tempWorkingDay)

    }, [calendar])

    React.useEffect(() => {

        if (filters.team && filters.date) {
            const year = filters.date.getFullYear();
            const month = filters.date.getMonth();
            const fromDate = new Date(year, month, 1);
            const toDate = new Date(year, month + 1, 0);
            const raw = {
                "filter": [
                    { type: 'safety' },
                    { team_id: filters.team.value },
                    { timeStamp: { $gte: new Date(fromDate).getTime() } },
                    { timeStamp: { $lte: new Date(toDate).getTime() } }
                ]
            }
            Apis.getDashboardData(raw).then((res) => {
                setDashboardData(res.data.data.dashboard_data)
            }).catch((error) => {
                console.log(error)
            })
        }

    }, [refresh, filters.team, filters.date])
    const dashboardDataByID = keyBy(dashboardData, 'timeStamp')
    const workingDaysByID = keyBy(workingDays, 'timeStamp')

    React.useEffect(() => {
        let daysInMonth = 0;

        const tempDates = [];
        let weekendcount = 0;
        let count = 0;
        if (filters.team && filters.date) {
            const year = filters.date.getFullYear();
            const month = filters.date.getMonth();

            daysInMonth = new Date(year, month + 1, 0).getDate();

            for (let i = 1; i <= daysInMonth; i++) {
                const date = new Date(year, month, i);
                const dayOfWeek = date.getDay();
                const timeStamp = new Date(date).getTime()

                const demo = {
                    dayOfWeek: 1,
                    timeStamp: timeStamp,
                    team_id: filters.team.value,
                    date: formatDate(date),
                    value: dashboardDataByID[timeStamp]?.value ? dashboardDataByID[timeStamp]?.value : ''
                };
                if (workingDaysByID[timeStamp]) {
                    if (workingDaysByID[timeStamp]?.isWorkingDay) {
                        count++;
                    } else {
                        demo.dayOfWeek = 0
                        demo.value = ''
                        weekendcount++;
                    }
                } else if (dayOfWeek === 0 || dayOfWeek === 6) {
                    demo.dayOfWeek = 0
                    demo.value = ''
                    weekendcount++;
                } else {
                    count++;
                }
                tempDates.push(demo);
            }
        }
        setSafetyCount({
            todayDays: daysInMonth,
            weekend: weekendcount,
            workingDay: count,
            incident: 0,
            no_incident: daysInMonth - count - weekendcount,
        });

        setSafetyData(tempDates);
    }, [dashboardData, filters.team, filters.date, workingDays]);


    React.useEffect(() => {
        let incident_count = 0;
        let no_incident_count = 0;

        safetyData && safetyData.forEach((elm) => {
            if (elm.value.length === 0) {
                no_incident_count++;
            } else {
                incident_count++;
            }
        });

        setSafetyCount((prevState) => ({
            ...prevState,
            incident: incident_count,
            no_incident: no_incident_count,
        }));
    }, [safetyData]);

    const onTeamChange = (option, params) => {
        setFilters({
            ...filters,
            [params.name]: option || '',
        });
    };

    const formatDate = (date) => {
        const dayOfWeek = date.toLocaleString("en-US", { weekday: "long" });
        const monthName = date.toLocaleString("en-US", { month: "short" });
        const day = date.getDate().toString().padStart(2, "0");
        const year = date.getFullYear();

        return `${dayOfWeek} ${monthName} ${day}, ${year}`;
    };

    const handleChange = (value, index) => {
        const updatedData = [...safetyData];
        updatedData[index] = { ...updatedData[index], value };
        setSafetyData(updatedData);
    };

    const handleSave = () => {
        const updatedData = [...safetyData];
        updatedData && updatedData.map((elm, index) => {
            const formData = {
                type: 'safety',
                team_id: elm.team_id,
                timeStamp: elm.timeStamp,
                value: elm.value
            }
            Apis.storeDashboardData(formData).then((res) => {
                if (updatedData.length === index + 1) {
                    swal({
                        title: 'Safety Data Updated',
                        icon: "success"
                    }).then((result) => {
                        setRefresh(!refresh)
                    })
                }

            }).catch((error) => {
                console.log(error)
            })

        })


    }


    return (
        <>
            <div id="layout-wrapper">
                <Header title='Dashboard Data - Safety | P2IC'></Header>
                <Sidebar title='Dashboard_data' show='dashboardDataSafety'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Safety</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Dashboard Data</li>
                                            <li className="breadcrumb-item active">Safety</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow">
                                <div className="col-lg-12 mb-4">
                                    <div className='container-fluid'>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <Label >
                                                    Team
                                                </Label>
                                                <ReactSelect
                                                    placeholder="Filter Team..."
                                                    options={teams && teams.map((i) => ({ value: i._id, label: i.name }))}
                                                    name='team'
                                                    value={filters.team}
                                                    onChange={onTeamChange}
                                                    isClearable
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <Label >
                                                    Choose Month & Year
                                                </Label>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={filters.date}
                                                    onChange={(date) => setFilters({
                                                        ...filters,
                                                        date: date,
                                                    })}
                                                    dateFormat="MMMM/yyyy"
                                                    showMonthYearPicker

                                                />
                                            </div>
                                            <div className="col-lg-4 mt-4">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className='col-lg-12 text-end '>
                                                            <button className='btn btn-danger' onClick={() => setFilters({ team: '', date: new Date() })}>Reset</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-8">
                                                            <div className="table-responsive">
                                                                <table className="table table-striped mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Date</th>
                                                                            <th scope="col">Enter Incidents Data</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {safetyData && safetyData.map((elm, index) => {

                                                                            if (safetyData.length == index + 1) {
                                                                                return (<>
                                                                                    <tr key={index} >
                                                                                        <td>{elm.date}</td>
                                                                                        <td>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                value={elm.dayOfWeek === 0 ? 'X' : elm.value}
                                                                                                readOnly={elm.dayOfWeek === 0}
                                                                                                onChange={(e) => handleChange(e.target.value.replace(/[^0-9]/g, ""), index)}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr key={index + 1}>
                                                                                        <td colSpan={2} className='text-center'>
                                                                                            <button className='btn btn-primary waves-effect waves-light' onClick={() => handleSave()}>Update</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>)
                                                                            } else {
                                                                                return (
                                                                                    <tr key={index} >
                                                                                        <td>{elm.date}</td>
                                                                                        <td>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                value={elm.dayOfWeek === 0 ? 'X' : elm.value}
                                                                                                readOnly={elm.dayOfWeek === 0}
                                                                                                onChange={(e) => handleChange(e.target.value.replace(/[^0-9]/g, ""), index)}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }

                                                                        })}



                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className={safetyDataCount.todayDays === 0 ? "d-none" : "container"} style={{ position: 'fixed' }}>
                                                                <div className="row align-items-start">
                                                                    <div className="col-lg-12 border">
                                                                        <ul className="list-group list-group-flush">
                                                                            <li className="list-group-item">Incident <span>{safetyDataCount.incident}</span></li>
                                                                            <li className="list-group-item">No Incident <span>{safetyDataCount.no_incident}</span></li>
                                                                            <li className="list-group-item">Working Days <span>{safetyDataCount.workingDay}</span></li>
                                                                            <li className="list-group-item">Weekend/Holidays <span>{safetyDataCount.weekend}</span></li>
                                                                            <li className="list-group-item">Total Days <span>{safetyDataCount.todayDays}</span></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
        </>
    );
}

export default Safety;